<template>
  <vs-chip :class="['ag-grid-cell-chip', chipClass(params.data.type)]">
    <span class="text-center" v-if="params.data.type">{{
      labeler(params.data.type)
    }}</span>
  </vs-chip>
</template>

<script>
export default {
  name: 'CellRendererInventorySalesType',
  computed: {
    chipClass() {
      const classes = {
        CASH: 'cash',
        POINTS: 'points',
        CASH_AND_POINTS: 'cash-and-points',
      };

      return (value) => {
        return classes[value] ?? classes.DEFAULT;
      };
    },
    labeler() {
      const labels = {
        CASH: 'Efectivo',
        POINTS: 'Puntos',
        CASH_AND_POINTS: 'Ambos',
      };

      return (value) => {
        return labels[value] ?? labels.DEFAULT;
      };
    },
  },
};
</script>

<style lang="scss" scpoped>
.ag-grid-cell-chip {
  margin: 0 auto;
  @apply font-bold;
  &.cash {
    background-color: #5c52a9;

    .vs-chip--text {
      @apply text-white;
    }
  }

  &.points {
    background-color: #3e874c;
    .vs-chip--text {
      @apply text-white;
    }
  }

  &.cash-and-points {
    background-color: #376f94;
    .vs-chip--text {
      @apply text-white;
    }
  }
}
</style>
